import { ImportedSvgIcons } from "types/icons";

import { getSortedSvgs } from "selectors/icons";

const rawProviderIcons = import.meta.glob("icons/providers/*", {
  eager: true,
  import: "default",
  query: "?react",
}) as ImportedSvgIcons;

export const PROVIDER_ICONS = getSortedSvgs(rawProviderIcons);
