import { forwardRef, InputHTMLAttributes } from "react";

import BlankInput from "./components/blank-input";

import { InputProps, PatternType } from "./duck/types";
import { PATTERN_PRESET } from "./duck/constants";

interface Props
  extends InputProps,
    Omit<InputHTMLAttributes<HTMLInputElement>, "size"> {
  patternType?: PatternType;
}

const Input = forwardRef<HTMLInputElement, Props>(
  ({ pattern, patternType, ...props }, ref) => (
    <BlankInput
      innerRef={ref}
      pattern={pattern || PATTERN_PRESET[patternType]}
      {...props}
    />
  ),
);

export default Input;
