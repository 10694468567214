import { ImportedSvgIcons } from "../types/icons";

export const getSortedSvgs = (iconsMap: ImportedSvgIcons) =>
  Object.entries(iconsMap)
    .map(([path, module]) => {
      const folders = path.split("/");
      const [id, name] = folders[folders.length - 1].split(".");
      module.id = Number(id);
      module.title = name;

      return module;
    })
    .sort((a, b) => a.id - b.id);
