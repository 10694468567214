import { CSSProperties } from "react";
import snakeCase from "lodash/snakeCase";

export const getCSSVariables = <K extends string>(
  variables: Record<K, string | number>,
) => {
  const cssVariables: Record<string, string | number> = {};
  for (const name in variables) {
    cssVariables[`--${snakeCase(name).replaceAll("_", "-")}`] = variables[name];
  }

  return cssVariables as CSSProperties;
};
