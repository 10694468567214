import { FC } from "react";
import joinClassNames from "classnames";

import { PROVIDER_ICONS } from "./duck/constants";

import classes from "./styles/classes.module.scss";

interface Props {
  className?: string;
}

const Partners: FC<Props> = ({ className }) => (
  <div className={joinClassNames(classes.wrapper, className)}>
    {PROVIDER_ICONS.map((Icon, index) => (
      <div key={Icon.title} className={classes.iconWrapper}>
        <Icon />
        {(index === 8 || index === 9) && (
          <p className={classes.label}>Coming soon</p>
        )}
      </div>
    ))}
  </div>
);

export default Partners;
